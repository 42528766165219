/** @jsx jsx */

import { jsx, Styled, Box, Flex } from 'theme-ui';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import { Container } from '../components/ui';

const NotFoundPage = () => (
  <Layout sticky={true}>
    <HelmetDatoCms>
      <title>404</title>
    </HelmetDatoCms>

    <Container pt={[108, 160]}>
      <Flex
        sx={{
          height: 800,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            textAlign: 'center'
          }}
        >
          <Styled.h2 sx={{ mb: 2 }}>Cette page n’est pas disponible</Styled.h2>
          <Styled.p>
            Le lien que vous avez suivi peut être brisé, ou la page peut avoir
            été supprimée
          </Styled.p>
        </Box>
      </Flex>
    </Container>
  </Layout>
);

export default NotFoundPage;
